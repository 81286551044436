import React, { useEffect, useState } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import useAuth from '../../auth/useAuth';
import NotAuthorized from '../../shared/NotAuthorized';
import Roller from '../../shared/Roller';

interface UseParamsProps {
    id: string
}

interface CampaignProps {
    allowAnonymous: boolean,
    allowComments: boolean,
    category: {
        id: number, 
        name: string, 
        description: string, 
        coverImage: string, 
        slug: string
    }
    collectAudienceInfo: boolean,
    description: string,
    design: {
        name: string
        previewImage: string,
    },
    id: string,
    isPublished: boolean,
    name: string,
    publishedAt: string
    slug: string,
    stats: {
        commentCount: number
        entryCount: string
        pageViews: string
    }
    tags: object[]
    type: string
    user: {
        brandName: string,
        coverPhoto: string,
        id: string,
        name: string,
        profilePhoto: string,
        username: string,
    }
}

const Campaign : React.FC = () => {
    let token = localStorage.getItem("inbrAdminToken");
    const auth = useAuth();

    if (token) {
        token = JSON.parse(token);
    }

	const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const { id } = useParams<UseParamsProps>();
    const [campaign, setCampaign] = useState<CampaignProps>()

    function getCampaign () : void {
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/campaigns/${id}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoading(false);
            if (result.error) {
                setError(result.error)
            } else {
                setCampaign(result.campaign);
            }
			// console.log(result);
		})
		.catch(error => {
			setError(error);
			setLoading(false);
		});
	}

    function unPublishCampaign () : void {
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/campaigns/${id}/unpublish`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoading(false);
			// console.log(result);
            if (result.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: result.message,
                }).then(() => history.push('/campaigns'))
            } else if (result.error) {
                Swal.fire({
                    icon: "error",
                    title: result.error === "Invalid Permission" ? "Sorry you are not authorized to perform this operation" : result.error
                });
            }
		})
		.catch(error => {
			setError(error);
			setLoading(false);
		});
	}

    const handleUnPublishCampaign = () => {
        setLoading(true);
        unPublishCampaign();
    }

    const parseISOString = (ISOString: string) : string => {
        let d = ISOString.split(/\D+/);
        let theUtcTime = new Date(Date.UTC(Number(d[0]), /* -- */Number(d[1]), Number(d[2]), Number(d[3]), Number(d[4]), Number(d[5]), Number(d[6])));
        return (
            theUtcTime
                .toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "numeric",
                    year: "numeric",
                })
                .replace(/ /g, "/") +
            ", " +
            theUtcTime.toLocaleTimeString()
        );
    };

    useEffect(() => {
        getCampaign();
        // eslint-disable-next-line
    }, []);

    if (loading) {
		return <Roller />
	} else if (error === "Invalid Permission") {
        return <NotAuthorized />
	} else if (error) {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{error}
		</div>
	}

    return (
        <div>
            <div>
                <NavLink to="/campaigns" className="custom-link">&larr; Back to all campaigns</NavLink>
            </div>
            <div className="row mt-3 mb-5 pb-5">
                <div className="col-md-6">
                    {campaign?.design?.previewImage ? (
                        <img src={campaign?.design.previewImage} className="preview_img" alt="campaign preview" />
                    ) : (
                        <div style={{
                            height: "100%",
                            border: "1px solid #ff0066",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "bold",
                        }}>NO PREVIEW IMAGE</div>
                    )}
                </div>
                <div className="col-md-6">
                    <div className="t-categories clearfix">
                        <div className="t-category campaign-cate">
                            <div className="wrap">
                                <h5 className="name text-ellipse" style={{ marginBottom: 0 }}>
                                    {!campaign?.category ? (
                                        (
                                            <span>No category</span>
                                        )
                                    ) : campaign?.category.name.includes("&amp;") ? (
                                        <span>
                                            {campaign?.category.name.replace("&amp;", "&")}
                                        </span>
                                    ) : (
                                        campaign?.category.name
                                    )}
                                </h5>
                            </div>
                        </div>
                    </div>
                    <br />
                    <h1>{campaign?.name}</h1>
                    <div>
                        <img
                            src={campaign?.user?.profilePhoto !== "" ? campaign?.user?.profilePhoto : "/assets/img/user.png"}
                            className="owner_img"
                            alt={campaign?.user.username}
                        />
                    </div>
                    <p>
                        By{" "}
                        <span>
                            <strong>{`${campaign?.user.name}`}</strong>
                        </span>
                    </p>
                    <div className="template-btn-div">
                        {
                            campaign?.isPublished ? (
                                <div className={`btn btn-success btn-md btn-block p-2`} style={{ cursor: "not-allowed" }}>
                                    Campaign has been published
                                </div>
                            ) : (
                                <button className={`btn btn-info btn-md btn-block p-2 ${auth?.adminProfile?.role === "viewer" ? "not-allowed" : ""}`} disabled={auth?.adminProfile?.role === "viewer" ? true : false } onClick={handleUnPublishCampaign}>
                                    Unpublish Campaign
                                </button>
                            )
                        }
                    </div>
                    <br />
                    <h2>About this campaign</h2>
                    <div>{campaign?.description}</div>
                </div>
                {/* <div className="col-md-12 mt-3 t-categories">
                    {campaign?.tags.map((tag, index) => (
                        <Tag key={index} {...tag} />
                    ))}
                </div> */}
            </div>
            <h5>Campaign Link: <a href={`https://inbranded.co/c/${campaign?.slug}`} rel="noreferrer" className="custom-link" target="_blank">https://inbranded.co/c/{campaign?.slug}</a></h5>
            <div className="grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex flex-row justify-content-between border-bottom">
                            <h4 className="card-title mb-1">More Information</h4>
                            <p className="text-muted mb-1">The Status</p>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="preview-list">
                                    <div className="preview-item border-bottom">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="preview-subject">Allow Annonymous</h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p className="text-muted">{campaign?.allowAnonymous ? "Yes" : "No" }</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="preview-item border-bottom">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="preview-subject">Allow Comments</h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p className="text-muted">{campaign?.allowComments ? "Yes" : "No" }</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="preview-item border-bottom">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="preview-subject">Collect Audience Information</h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p className="text-muted">{campaign?.collectAudienceInfo ? "Yes" : "No" }</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="preview-item border-bottom">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="preview-subject">Is Campaign Published</h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p className="text-muted">{campaign?.isPublished ? "Yes" : "No" }</p>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        campaign?.isPublished && (
                                            <div className="preview-item border-bottom">
                                                <div className="preview-item-content d-sm-flex flex-grow">
                                                    <div className="flex-grow">
                                                        <h6 className="preview-subject">Date Campaign Published</h6>
                                                    </div>
                                                    <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                        <p className="text-muted">{parseISOString(campaign?.publishedAt)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className="preview-item border-bottom">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="preview-subject">Number of Comments</h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p className="text-muted">{campaign?.stats.commentCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="preview-item border-bottom">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="preview-subject">Number of  Entries</h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p className="text-muted">{campaign?.stats.entryCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* LAST ITEM IN THE LIST */}
                                    <div className="preview-item">
                                        <div className="preview-item-content d-sm-flex flex-grow">
                                            <div className="flex-grow">
                                                <h6 className="preview-subject">Page Views</h6>
                                            </div>
                                            <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                                                <p className="text-muted">{campaign?.stats.pageViews}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Campaign
