import React, { useEffect, useState } from 'react';
import { useParams, NavLink } from "react-router-dom";
import Swal from 'sweetalert2';
import CampaignTableRow, { CampaignTableRowProps } from '../../components/CampaignTableRow';
import NotAuthorized from '../../shared/NotAuthorized';
import Roller from '../../shared/Roller';
import { CampaignResults } from '../campaigns/Campaigns';

interface UseParamsProps {
    userId: string
}

const User: React.FC = () => {    
	const { userId } = useParams<UseParamsProps>();
    let rawData = localStorage.getItem("UserPageData");
    const [userData, setUserData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const [campaigns, setCampaigns] = useState<[]>([]);
    const [error, setError] = useState("");
    const [campaignResult, setCampaignResult] = useState<CampaignResults>({
        category : "",
        orderBy : "",
        page : "",
        perPage : "",
        query : "",
        sortBy : "",
        userId : "",
    });
    let token = localStorage.getItem("inbrAdminToken");

    if (token) {
        token = JSON.parse(token);
    }

    function getCampaigns (query: string = campaignResult.query, userId: string = "", perPage: number = 10, page: number = 1, category: string = "", sortBy: string = campaignResult.sortBy, orderBy: string = campaignResult.orderBy) : void {
		setLoading(true);
        let queryString: string = `${query}&userId=${userId}&perPage=${perPage}&page=${page}&category=${category}&sortBy=${sortBy}&orderBy=${orderBy}`;

		// console.log("starting");
		// console.log(dataFrom, dataTo)
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/campaigns?query=${queryString}`, requestOptions)
		.then(response => response.json())
		.then(result => {
            setLoading(false);
            if (result.status === "ok") {
                setCampaigns(result.campaigns);
                setCampaignResult(result.results);
            } else if (result.error) {
                setError(result.error);
                if (result.error !== "Invalid Permission") {
                    Swal.fire({
                        icon: "error",
                        title: result.error
                    });
                }
            }
            // console.log(result);
		})
		.catch(error => {
            console.log(error);
			setError("Error please check your internet connection");
			setLoading(false);
		});
	}

    useEffect(() => {
        if (rawData) {
            setUserData(JSON.parse(rawData));
        }
    }, [rawData])

    useEffect(() => {
        // console.log(userId);
        getCampaigns("", userId);
        // eslint-disable-next-line
    }, [userId]);

    const [sortByState, setSortByState] = useState("");
	const [orderByState, setOrderByState] = useState("");

	const sortBySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		// console.log(e.target.value);

		if (e.target.value === "") {
			setSortByState("");
			getCampaigns("", "", 10, 1, "", "", orderByState);
		} else {
			setSortByState(e.target.value);
			getCampaigns("", "", 10, 1, "", e.target.value, orderByState);
		}
	};

	const orderBySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		// console.log(e.target.value);

		if (e.target.value === "") {
			setOrderByState("");
			getCampaigns("", "", 10, 1, "", sortByState, "");
		} else {
			setOrderByState(e.target.value);
			getCampaigns("", "", 10, 1, "", sortByState, e.target.value);
		}
	};

    let searchSeconds = 1000; // milliseconds for the search timeout
	const [nameSearchValue, setNameSearchValue] = useState("");

	useEffect(() => {
		let nameSearchId: NodeJS.Timeout;

		if (nameSearchValue) {
			nameSearchId = setTimeout(() => {
				setLoading(true);
				getCampaigns(nameSearchValue);
			}, searchSeconds);
		}

		return () => clearTimeout(nameSearchId);

		// eslint-disable-next-line
	}, [nameSearchValue, searchSeconds]);

    const [currentPage, setCurrentPage] = useState(1);

	const prev = () => {
		if (Number(campaignResult.page) === 1) {
			return;
		} else {
			getCampaigns(campaignResult.query, "", 10, Number(currentPage) - 1, "", campaignResult.sortBy, campaignResult.orderBy);
			setCurrentPage(currentPage - 1);
			// console.log("previous");
		}
	};

	const next = () => {
		if (campaigns.length !== 0) {
			setCurrentPage(Number(campaignResult.page) + 1);
			getCampaigns(campaignResult.query, "", 10, Number(campaignResult.page) + 1, "", campaignResult.sortBy, campaignResult.orderBy);
		} else {
			return;
		}
	};

    // useEffect(() => console.log(campaigns, "campaigns"), [campaigns]);
    // useEffect(() => console.log(userData, "userData"), [userData]);

    const parseISOString = (ISOString: string) : string => {
        let d = ISOString ? ISOString.split(/\D+/) : "";
        let theUtcTime = new Date(Date.UTC(Number(d[0]), Number(d[1]), Number(d[2]), Number(d[3]), Number(d[4]), Number(d[5]), Number(d[6])));
        return (
            theUtcTime
                .toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "numeric",
                    year: "numeric",
                })
                .replace(/ /g, "/") +
            ", " +
            theUtcTime.toLocaleTimeString()
        );
    };

    if (loading) {
		return <Roller />
	} else if (error === "Invalid Permission") {
        return <NotAuthorized />
    } else if (error) {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{error}
		</div>
	}

    return (
        <div>
            <div>
                <NavLink to="/users" className="custom-link">&larr; Back to all users</NavLink>
            </div>
            <div className="row mt-3 mb-5 pb-5">
                <div className="col-md-6">
                    {userData?.coverPhoto ? (
                        <img src={userData?.coverPhoto} className="preview_img" alt="campaign preview" />
                    ) : (
                        <div style={{
                            height: "100%",
                            border: "1px solid #ff0066",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "bold",
                        }}>NO COVER PHOTO</div>
                    )}
                </div>
                <div className="col-md-6">
                    <h1>{`${userData?.firstName} ${userData?.lastName}`}</h1>
                    <div>
                        <img
                            src={userData?.profilePhoto ? userData?.profilePhoto : "/assets/img/user.png"}
                            className="owner_img"
                            alt={userData?.username ?? "username"}
                        />
                    </div>
                    <p>
                        Email: &nbsp;
                        <span>
                            <strong>{userData?.email ?? "No Email"}</strong>
                        </span>
                    </p>
                    <p>
                        Username: &nbsp;
                        <span>
                            <strong>{userData?.username ?? "No Username"}</strong>
                        </span>
                    </p>
                    <p>
                        Description: &nbsp;
                        <span>
                            <strong>{userData?.bestDescribed ?? "No Data"}</strong>
                        </span>
                    </p>
                    <p>
                        Date Created:  &nbsp;
                        <span>
                            <strong>{parseISOString(userData?.createdAt) ?? ""}</strong>
                        </span>
                    </p>
                    <p>
                        Date Updated: &nbsp;
                        <span>
                            <strong>{parseISOString(userData?.updatedAt) ?? ""}</strong>
                        </span>
                    </p>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <div className="card mb-1">
                        <div className="card-body p-3 d-flex justify-content-between" id="searchBody">
                            <div className="form-group m-0 template-search-div">
                                <label>Search by name</label>                            
                                <input type="text" className="form-control" id="searchQuery" placeholder="Search by name" 
                                    onChange={(e) => {
                                        if (e.target.value === "") {
                                            setNameSearchValue("");
                                            setTimeout(() => {
                                                getCampaigns();
                                            }, 1000);
                                        } else {
                                            setNameSearchValue(e.target.value);
                                        }
                                    }}
                                    value={nameSearchValue} 
                                />
                            </div>
                            <div className="form-group m-0 template-search-div">
                                <label>Sorting</label>
                                <select className="form-control sort-select" onChange={sortBySelect} value={sortByState}>
                                    <option defaultValue="">Sort by</option>
                                    <option value="alphabetical">Alphabetical</option>
                                    <option value="dateCreated">Date created</option>
                                    <option value="lastModified">Last modified</option>
                                </select>
                            </div>
                            <div className="form-group m-0 template-search-div">  
                                <label>Ordering</label>                          
                                <select className="form-control sort-select" onChange={orderBySelect} value={orderByState}>
                                    <option defaultValue="">Order by</option>
                                    <option value="ascending">Ascending</option>
                                    <option value="descending">Descending</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body no-padding">
                    <div className="table-wrap table-responsive">
                        <table className="table m-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Comments</th>
                                    <th>Views</th>
                                    <th>Entries</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    campaigns.length === 0 ? (
                                        <tr>
                                            <td colSpan={7} className="custom-text-capitalize" style={{ textAlign: "center" }}>
                                                No Data Found
                                            </td>
                                        </tr>
                                    ) : (
                                        campaigns.map((campaign : CampaignTableRowProps ) => <CampaignTableRow key={campaign.id} {...campaign} />)
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card">
                    <div className="card-footer clearfix">
                        <ul className="pagination pagination-sm m-0 float-right">
                            <li className={`${Number(campaignResult.page) === 1 ? "dont-click" : "you-can-click"} page-item`}>
                                <span className="page-link" onClick={prev}>
                                    « Previous
                                </span>
                            </li>
                            <li className={`${campaigns.length !== 0 ? "you-can-click" : "dont-click"} page-item`}>
                                <span className="page-link" onClick={next}>
                                    Next »
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default User
