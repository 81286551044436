import React, { useEffect, useState, useRef } from 'react'
import { NavLink/* , useHistory */ } from 'react-router-dom';
import TemplateComponent, { TemplateProps } from '../../components/TemplateComponent';
import TemplateCategory from '../../components/TemplateCategory';
import Roller from '../../shared/Roller';
import 'overlayscrollbars/css/OverlayScrollbars.css';
// import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import './Templates.scss';
import Swal from 'sweetalert2';
import { CategoryProps } from '../categories/Categories';
import NotAuthorized from '../../shared/NotAuthorized';
interface TemplateResult {
	category: string,
	page: number,
	perPage: number,
	query: string,
	tag: string
}

const Templates = () => {
    let token = localStorage.getItem("inbrAdminToken");
	// const history = useHistory();

    if (token) {
        token = JSON.parse(token);
    }

    const [loading, setLoading] = useState(true);
    const [noPermission, setNoPermission] = useState(false);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [loadingTemplates, setLoadingTemplates] = useState(true);
    const [error, setError] = useState("");
	const [templates, setTemplates] = useState<[]>([]);
	const [templateResult, setTemplateResult] = useState<TemplateResult>({
		category: "",
		page: 1,
		perPage: 20,
		query: "",
		tag: ""
	});

	const [categories, setCategories] = useState([]);

	let searchSeconds = 1000; // milliseconds for the search timeout

	const [nameSearchValue, setNameSearchValue] = useState("");

	useEffect(() => {
		let nameSearchId: NodeJS.Timeout;

		if (nameSearchValue) {
			nameSearchId = setTimeout(() => {
				setLoading(true);
				setLoadingTemplates(true);
				getTemplates(nameSearchValue, "", "", 0)
			}, searchSeconds);
		}

		return () => clearTimeout(nameSearchId);

		// eslint-disable-next-line
	}, [nameSearchValue, searchSeconds]);

	const [tagSearchValue, setTagSearchValue] = useState("");

	useEffect(() => {
		let tagSearchId: NodeJS.Timeout;

		if (tagSearchValue) {
			tagSearchId = setTimeout(() => {
				setLoading(true);
				setLoadingTemplates(true);
				getTemplates("", "", tagSearchValue, 0);
			}, searchSeconds);
		}

		return () => clearTimeout(tagSearchId);

		// eslint-disable-next-line
	}, [tagSearchValue, searchSeconds]);

	// useEffect(() => console.log(nameSearchValue, "nameSearchValue"), [nameSearchValue])

    function getTemplates (query: string = "", category: string = "", tag: string = "", page: number = 0) : void {
		setLoading(true);
		setLoadingTemplates(true);
        let queryString: string = `${query}&page=${page}&category=${category}&tag=${tag}`;

		// console.log("starting");
		// console.log(dataFrom, dataTo)
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/templates?query=${queryString}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoadingTemplates(false);
			if (result.status === "ok") {
				setTemplates(result.templates);
				setTemplateResult(result.results);
			} else if (result.error) {
				setError(result.error);
			}
			// console.log(result);
		})
		.catch(error => {
			setError(error);
			setLoadingTemplates(false);
		});
	}

    function getCategories () : void {
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/categories`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoadingCategories(false);
			if (result.categories.length > 0) {
				setCategories(result.categories);
			} else if (result.error === "Invalid Permission") {
				setNoPermission(true)
			}
			
			// console.log(result);
		})
		.catch(error => {
			setError("Type Error: Failed to fetch");
			console.log(error);
			setLoadingCategories(false);
		});
	}

	const [currentPage, setCurrentPage] = useState(1);

	const prev = () => {
		if (Number(templateResult.page) === 1) {
			return;
		} else {
			getTemplates(nameSearchValue, templateResult.category, tagSearchValue, Number(currentPage) - 1)
			setCurrentPage(currentPage - 1);
			// console.log("previous");
		}
	};

	const next = () => {
		if (templates.length !== 0) {
			setCurrentPage(Number(templateResult.page) + 1);
			getTemplates(nameSearchValue, templateResult.category, tagSearchValue, Number(templateResult.page) + 1)
		} else {
			return;
		}
	};

	const sideScroll = (element: any, speed : number, distance : number, step : number) => {
		let scrollAmount = 0;
		const slideTimer = setInterval(() => {
			element.scrollLeft += step;
			scrollAmount += Math.abs(step);
			if (scrollAmount >= distance) {
				clearInterval(slideTimer);
			}
		}, speed);
	};

	function deleteTemplate (id: string) : void {
		setLoading(true);
		setLoadingTemplates(true);

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'DELETE',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/templates/${id}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoading(false);
			// console.log(result);
            if (result.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: result.message,
                }).then(() => getTemplates());
            }
		})
		.catch(error => {
			setError(error);
            Swal.fire({
                icon: "error",
                title: error.error
            })
			setLoading(false);
		});
	}

	const contentWrapper = useRef(null);

    useEffect(() => {
		getCategories();
		getTemplates();
		// eslint-disable-next-line
    }, []);

	useEffect(() => {
		if (!loadingCategories && !loadingTemplates) {
			setLoading(false);
		}
	}, [loadingCategories, loadingTemplates, loading]);

	// useEffect(() => {
		// console.log(templates, "templates");
		// console.log(categories, "categories");
		// console.log(templateResult, "templateResult");
	// }, [templates, categories, templateResult]);

	useEffect(() => {
		setNameSearchValue(templateResult.query);
		setTagSearchValue(templateResult.tag);
	}, [templateResult])

	if (loading) {
		return <Roller />
	} else if (error === "Invalid Permission") {
		return <NotAuthorized />
	} else if (error) {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{error}
		</div>
	} 

	// const scrollRef = React.createRef<OverlayScrollbarsComponent>();

    return (
        <div>
			<div className="card mb-1">
				<div className="card-body p-3 d-flex justify-content-between" id="searchBody">
					<div className="form-group m-0 template-search-div">
						<label>Search by name</label>
						<input
							type="text"
							className="form-control"
							placeholder="Search Templates By Name"
							onChange={(e) => {
								if (e.target.value === "") {
									setNameSearchValue("");
									setTimeout(() => {
										getTemplates();
									}, 1000);
								} else {
									setNameSearchValue(e.target.value);
								}
							}}
							value={nameSearchValue}
						/>
					</div>
					{/* <div className="form-group m-0 template-search-div">
						<label>Search By Category</label>
						<input type="text" className="form-control" placeholder="Search Templates By Category" onChange={(e) => setCategorySearchValue(e.target.value)} />
					</div> */}
					<div className="form-group m-0 template-search-div">
						<label>Search by tag</label>
						<input
							type="text"
							className="form-control"
							placeholder="Search Templates By Tag"
							onChange={(e) => {
								if (e.target.value === "") {
									setTagSearchValue("");
									setTimeout(() => {
										getTemplates();
									}, 1000);
								} else {
									setTagSearchValue(e.target.value);
								}
							}}							
							value={tagSearchValue}
						/>
					</div>
				</div>
			</div>
            {
				!noPermission && (
					<div className="mb-1 mt-3 scroll-div">
						<span
							onClick={() => {
								sideScroll(contentWrapper.current, 1, 600, -85);
							}}
							className="left-and-right-t-category"
							style={{ marginLeft: "1%" }}
						>
							&#8249;
						</span>
						<div className="t-categories" style={{ height: "35px", display: "flex", overflow: "hidden", width: "90%", margin: "auto" }} ref={contentWrapper}>
							<div className="t-category">
								<NavLink exact to="/templates" activeClassName="active">
									<div className="wrap">
										<h5 className="name text-ellipse">All</h5>
									</div>
								</NavLink>
							</div>
							{categories.map((category: CategoryProps) => (
								<TemplateCategory key={category.id} name={category.name} slug={category.slug} sortingFunction={getTemplates} />
							))}
						</div>
						<span
							onClick={() => {
								sideScroll(contentWrapper.current, 1, 600, 85);
							}}
							className="left-and-right-t-category"
							style={{ marginRight: "1%" }}
						>
							&#8250;
						</span>
					</div>
				)
			}
			<div className="templates-div">
				{
					templates.length === 0 ? (
						<div style={{ margin: "auto" }}>
							No Data Found
						</div>
					) : (
						templates.map((template: TemplateProps) => <TemplateComponent key={template.id} deleteTemplate={deleteTemplate} previewImage={template.previewImage} stats={template.stats} publishedAt={template.publishedAt} id={template.id} name={template.name} />)
					)					
				}
			</div>
			<div className="card">
				<div className="card-footer clearfix">
					<ul className="pagination pagination-sm m-0 float-right">
						<li className={`${Number(templateResult.page) === 1 ? "dont-click" : "you-can-click"} page-item`}>
							<span className="page-link" onClick={prev}>
								« Previous
							</span>
						</li>
						<li className={`${templates.length !== 0 ? "you-can-click" : "dont-click"} page-item`}>
							<span className="page-link" onClick={next}>
								Next »
							</span>
						</li>
					</ul>
				</div>
			</div>
        </div>
    )
}

export default Templates
