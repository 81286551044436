import React from 'react'
import { NavLink } from 'react-router-dom';

export interface CampaignTableRowProps {
    category: null
    id: string,
    isPublished: false
    name: string,
    stats: {
        commentCount: number, 
        entryCount: number, 
        pageViews: number
    },
    type: string
}

const CampaignTableRow : React.FC<CampaignTableRowProps> = ({ name, id, type, isPublished, stats: { pageViews, entryCount, commentCount } }) => {

    return (
        <tr>
            <td>
                <NavLink className="custom-link" to={`/campaign/${id}`}>{name}</NavLink>
            </td>
            <td className="custom-text-capitalize">{type}</td>
            <td>
                {
                    isPublished ? <span className="badge badge-outline-success">Published</span> : <span className="badge badge-outline-warning">Not published</span>
                }
            </td>
            <td>{commentCount}</td>
            <td>{pageViews}</td>
            <td>{entryCount}</td>
            <td>
                <NavLink  to={`/campaign/${id}`} className="btn btn-sm btn-default custom-link">
                    View &rarr;{" "}
                </NavLink>
            </td>
        </tr>
    )
}

export default CampaignTableRow
