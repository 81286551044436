import React from 'react'
import { NavLink } from 'react-router-dom';

interface TagProps {
    name: string,
    slug: string,
}

const Tag : React.FC<TagProps> = ({ name, slug }) => {
    return (
        <div className="t-category" style={{ minWidth: "50px" }}>
            <NavLink to={`/tags/${slug}`} className="active">
                <div className="wrap">
                    <h5 className="name text-ellipse">
                        <span>
                            {name}
                        </span>
                    </h5>
                </div>
            </NavLink>
        </div>

    )
}

export default Tag
