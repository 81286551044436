import React, { useEffect, useState } from 'react';
import Select from "react-select";
import Roller from '../shared/Roller';
import '../admin-pages/templates/Templates.scss';
import Swal from 'sweetalert2';

interface EditTemplateProps {
    name : string,
    description	: string,
    categoryIdName : string,
    tags : {
        name: string
    }[],
    id: string,
    closeModal: () => void,
    getTemplate: () => void
}

interface CategoryObject {
    value: number,
    label: string
}

interface EditTemplateState {
    name : string,
    description	: string,
    categoryId : number,
    tags : string[],
}

const EditTemplate: React.FC<EditTemplateProps> = ({ name, description, categoryIdName, tags, id, closeModal, getTemplate}) => {
    let token = localStorage.getItem("inbrAdminToken");

    if (token) {
        token = JSON.parse(token);
    }

    // console.log(name, "name", description, "description", categoryIdName, "category name", tags, "tag araay", id, "id")
    
    const [loadingCategories, setLoadingCategories] = useState(true);
	const [categories, setCategories] = useState([]);
    const [currentCategory, setCurrentCategory] = useState<CategoryObject>();
    const [error, setError] = useState("");
    // const [removeValueAttr, setRemoveValueAttr] = useState(false);
    const [updating, setUpdating] = useState(false);

    const [editTemplateState, setEditTemplateState] = useState<EditTemplateState>({
        name: "",
        description: "",
        categoryId: 0,
        tags: [""]
    });

    function filterTag (tag: string) : string {
        return tag
            .replace(/[^\w -]/g, "")
            .trim()
            .replace(/\W+/g, "-");
    }

    useEffect(() => {
        setEditTemplateState({
            name: name,
            description: description,
            categoryId: 0,
            tags: tags.map(tag => tag.name),
        });
    }, [tags, name, description]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        // console.log(e, "the event")

        setEditTemplateState({
            ...editTemplateState,
            [name]: value
        })
    }

    // useEffect(() => console.log(editTemplateState, "editTemplateState"), [editTemplateState]);

    function getCategories () : void {
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/categories`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoadingCategories(false);

            const newCategoryArr = result.categories.map((category: { name: string; id: any; }) : CategoryObject => {
                let splitArr;

                if (category.name.includes("&amp;")) {
                    splitArr = category.name.split("&amp;");
                }

                return {
                    value: category.id,
                    label: category.name.includes("&amp;") ? `${splitArr?.[0]} & ${splitArr?.[1]}` : category.name,
                }
            });

            setCategories(newCategoryArr);
			// setCategories(result.categories);
			// console.log(result);
		})
		.catch(error => {
			setError("There seems to be a problem, please check your connection");
			setLoadingCategories(false);            
            console.log(error, "the error");
		});
	}

    useEffect(() => {
        // console.log(currentCategory, "current category");
        setEditTemplateState((e) => (
            {
                ...e,
                categoryId: currentCategory?.value ?? 0
            }
        ))
    }, [currentCategory]);

    useEffect(() => {
        if (categories.length > 0) {
            let splitArr;
            if (categoryIdName.includes("&amp;")) {
                splitArr = categoryIdName.split("&amp;");
            }
            // console.log(categories, "categories");
            // To get the current category if it has already been updated before
            const thePassedCategoryName = categoryIdName.includes("&amp;") ? `${splitArr?.[0]} & ${splitArr?.[1]}` : categoryIdName;
            const current = categories.filter((category: CategoryObject) => thePassedCategoryName === category.label);

            let theCurrent: CategoryObject = current[0];
            setCurrentCategory({
                ...currentCategory,
                ...theCurrent
            });
        }
        // eslint-disable-next-line
    }, [categories, categoryIdName]);

    const customStyles = {
        control: (base: any, state: { isFocused: any; }) => ({
          ...base,
          background: "#2A3038",
          // match with the menu
          borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
          // Overwrittes the different states of border
          borderColor: state.isFocused ? "#6c728c" : "#6c728c",
          // Removes weird border around container
          boxShadow: state.isFocused ? null : null,
          "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#46c35f" : "#6c728c"
          },
        }),
        menu: (base: any) => ({
          ...base,
          // override border radius to match the box
          borderRadius: 0,
          // kill the gap
          marginTop: 0,
        //   color: "#6c728c"
        }),
        menuList: (base: any) => ({
          ...base,
          // kill the white space on first and last option
          padding: 0
        }),
        input: (base: any) => ({
          ...base,
          color: "white"
        }),
    };

    function updateTemplate () : void {
		// console.log("starting");
		// console.log(dataFrom, dataTo)
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

        let sentBody = JSON.stringify({
            name: editTemplateState.name,
            description: editTemplateState.description,
            categoryId: editTemplateState.categoryId,
            tags: editTemplateState.tags,
        });
    
        let requestOptions = {
            method: "PATCH",
            headers: myHeaders,
            body: sentBody,
        };

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/templates/${id}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setUpdating(false);
			// console.log(result);
            if (result.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: result.message
                }).then(() => getTemplate())
            }
            closeModal();
		})
		.catch(error => {
			setError(error);
		});
	}

    const chipperInputElem = document.getElementById("chipperInputId");

    const focusChipperInputElem = () => {
        if (chipperInputElem) {
            chipperInputElem.focus();
        }
    }

    const handleTagChange = (e: React.KeyboardEvent<HTMLInputElement> ) => {
        let targetHtmlElem = (e.target as HTMLInputElement);
        // const hiddenChipperInput = document.getElementById(`hiddenchipperInputId`);

        if (targetHtmlElem.value === ",") {
            targetHtmlElem.value = "";
        } else if (targetHtmlElem.value !== "") {
            if ((e.key === ",")) {
                // console.log('comma or enter has been pressed');
                // console.log(targetHtmlElem.value);
                let splitted;
                // setChipperError("");

                if (e.key === ",") {
                    e.preventDefault();
                    // console.log(e.key, "key");
                    splitted = targetHtmlElem.value.split(',');
                    // console.log(splitted, 'removed the comma');
                    // console.log(hiddenChipperInput.);
                    // // if (hiddenChipperInput) {
                    // //     hiddenChipperInput.value += `${splitted[0]},`;
                    // // }
                    const filteredTag = filterTag(splitted[0]);
                    // console.log(`hidden${chipperInputId}`);
                    setEditTemplateState({
                        ...editTemplateState,
                        tags: [...editTemplateState.tags, filteredTag],
                    });
                } else if (e.key === "Enter") {
                    e.preventDefault();
                    // console.log(e.key, "key");
                    splitted = targetHtmlElem.value.split(',');  
                    // hiddenChipperInput.value += `${splitted[0]},`;
                    setEditTemplateState({
                        ...editTemplateState,
                        tags: [...editTemplateState.tags, targetHtmlElem.value],
                    });
                }
                targetHtmlElem.value = "";
            }
        } else {
            targetHtmlElem.value = "";
        }
    }

    const deleteChipperTag = (statToDelete: number) => {
        // console.log(statToDelete);
        const newArr = editTemplateState.tags.filter((stats, index) => index !== statToDelete);
        // console.log(newArr);
        setEditTemplateState({
            ...editTemplateState,
            tags: [...newArr],
        });
    }

    useEffect(() => {
        getCategories();

        // eslint-disable-next-line
    }, []);

    const handleUpdate = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (editTemplateState.tags.length > 0 && editTemplateState.categoryId !== 0) {
            setUpdating(true);
            updateTemplate();
        } else {
            Swal.fire({
                icon: "error",
                title: "At least one tag is required and Also Select a category from the dropdown"
            })
        }
    }

    if ((loadingCategories) || (updating )) {
		return <Roller />
	} else if (error) {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{error}
		</div>
	}

    return (
        <>
            <button className="close-modal" type="button" onClick={closeModal} >
                <span aria-hidden="true">&times;</span>
            </button>
            <form className="form-horizontal" onSubmit={handleUpdate}>
                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">
                        Name
                    </label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control edit-template-form-control" required id="inputName" placeholder="Name" name="name" value={editTemplateState.name} onChange={handleChange} />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="description" className="col-sm-2 col-form-label">
                        Description
                    </label>
                    <div className="col-sm-10">
                        <textarea className="form-control edit-template-form-control" id="description" required rows={5} placeholder="Short description about this list" name="description" value={editTemplateState.description} onChange={handleChange} />
                    </div>
                </div>
                <div className="form-group row">
                    {
                        categories ? (
                            <>
                                <label htmlFor="description" className="col-sm-2 col-form-label">
                                    Select category
                                </label>
                                <div className="col-sm-10">
                                    {
                                        currentCategory?.label && currentCategory?.value ? (
                                            <Select
                                                options={categories}
                                                name="categoryId"
                                                defaultValue={{ label: currentCategory?.label ? currentCategory?.label : "", value: currentCategory?.value ? currentCategory?.value: 0 }}
                                                // onChange={handleSelectChange}
                                                onChange={(value, label) => {
                                                    setEditTemplateState({
                                                        ...editTemplateState,
                                                        categoryId: Number(value?.value) ?? 0
                                                    })
                                                }}                                                
                                                // onFocus={() => setRemoveValueAttr(true)}
                                                className="edit-template-select"
                                                styles={customStyles}
                                                menuColor='red'
                                            />
                                        ) : currentCategory ? (
                                            <Select
                                                options={categories}
                                                name="categoryId"
                                                defaultValue={{ label: "Please select a category", value: 0 }}
                                                onChange={(value, label) => {
                                                    setEditTemplateState({
                                                        ...editTemplateState,
                                                        categoryId: Number(value?.value) ?? 0
                                                    })
                                                }}                                        
                                                className="edit-template-select"
                                                styles={customStyles}
                                                menuColor='red'
                                            />
                                        ) : (
                                            <div className="lds-roller" style={{display: "flex"}}>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        )
                                    }   
                                </div>
                            </>
                        ) : (
                            null
                        )
                    }
                </div>
                <div className="form-group row">
                    <label htmlFor="mainTagInput" className="col-sm-2 col-form-label">Tags (comma separated)</label>
                    <div className="tags-input edit-template-form-control" data-name="tags-input" onClick={focusChipperInputElem}>
                        {
                            editTemplateState.tags.map((theTag, index) => <div className="tag-like-elem" key={index}>
                                <span className="tag-like-content">{theTag}</span>
                                <span className="tag-like-elem-close" onClick={() => deleteChipperTag(index)}></span>
                            </div>)
                        }
                        <input
                            className="main-input"
                            id="chipperInputId"
                            name="tags"
                            type="text"
                            onKeyDown={handleTagChange}
                            // onFocus={changeInputBorderColor}
                            // onBlur={unChangeInputBorderColor}
                        />
                        <input id="hiddenchipperInputId" type="hidden" name="data-name" />
                    </div>
                </div>
                <button className="btn btn-block btn-success p-2.5" type="submit">Update Template</button>
            </form>
        </>
    )
}

export default EditTemplate

