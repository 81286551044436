// import React, {Suspense/* , lazy  */} from "react";
import { Route, Switch,/*  Route, Redirect */ } from "react-router-dom";
import BillingCycles from "./admin-pages/accounting/BillingCycles";
import Admin from "./admin-pages/admin/Admin";
import Admins from "./admin-pages/admin/Admins";
import Campaign from "./admin-pages/campaigns/Campaign";
import Campaigns from "./admin-pages/campaigns/Campaigns";
import Categories from "./admin-pages/categories/Categories";
import Category from "./admin-pages/categories/Category";

// import Spinner from "../app/shared/Spinner";
import Login from "./admin-pages/Login";
import Profile from "./admin-pages/profile/Profile";
import Template from "./admin-pages/templates/Template";
import Templates from "./admin-pages/templates/Templates";
import User from "./admin-pages/users/User";
import Users from "./admin-pages/users/Users";
import PrivateRoute from "./auth/PrivateRoute";
import Dashboard from "./dashboard/Dashboard";
import Error404 from "./error-pages/Error404";

// const Dashboard = lazy(() => import("./dashboard/Dashboard"));
// const Error404 = lazy(() => import("./error-pages/Error404"));
// const Login = lazy(() => import("./admin-pages/Login"));

const AppRoutes = () => {
		return (
			<Switch>
				{/* <Route path="/login" component={Login} /> */}
				<Route exact path="/" component={Login} />
				<PrivateRoute exact path="/dashboard" component={Dashboard} />
				<PrivateRoute exact path="/templates" component={Templates} />
				<PrivateRoute exact path="/template/:id" component={Template} />
				<PrivateRoute exact path="/categories" component={Categories} />
				<PrivateRoute exact path="/category/:id" component={Category} />
				<PrivateRoute exact path="/campaigns" component={Campaigns} />
				<PrivateRoute exact path="/campaign/:id" component={Campaign} />
				<PrivateRoute exact path="/accounting/billing-cycles" component={BillingCycles} />
				<PrivateRoute exact path="/users" component={Users} />
				<PrivateRoute exact path="/user/:userId" component={User} />
				<PrivateRoute exact path="/admins" component={Admins} />
				<PrivateRoute exact path="/admin/:id" component={Admin} />
				<PrivateRoute exact path="/profile" component={Profile} />
				<PrivateRoute exact path="/error-pages/error-404" component={Error404} />						
			</Switch>
		);
}

export default AppRoutes;
