import React, { useEffect, useRef } from "react";
// import Button from "../button/Button";
// import CloseIcon from "../CloseIcon";
import "./Modal.scss";

interface ModalProps {
    children: any,
    show: boolean,
}

const Modal : React.FC<ModalProps> = ({ /* modalStyle,  */children, show, /* onClose,backdropStyle  */ }) => {
    const modalRef = useRef<any>(null);

    useEffect(() => {
        if (show) {
            modalRef.current.classList.add("visible");
        } else {
            modalRef.current.classList.remove("visible");
        }
    }, [show]);

    return (
        <>
            <div ref={modalRef} /* style={backdropStyle}  */className={`modal-custom`}>
                {/* <Button
                    onClick={onClose}
                    style={{
                        width: 60,
                        height: 40,
                        position: "absolute",
                        top: 0,
                        right: 0,
                        margin: "1rem",
                        cursor: "pointer"
                    }}
                    className={styles.close__btn}
                >
                    <CloseIcon
                        height="20px"
                        width="20px"
                        className={styles.close__icon}
                    />
                        Close
                </Button> */}
                <div /* style={modalStyle}  */className={`modal__wrap`}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default Modal;
