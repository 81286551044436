import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { /* Collapse, Dropdown */ } from "react-bootstrap";
import { Trans } from "react-i18next";
import logoMini from "../../assets/images/logo/logo.png";
import logo from "../../assets/images/logo/logo-horizontal.png";
import useAuth from "../auth/useAuth";

const Sidebar = () => {
	const location = useLocation();
	const [sideBarState, setSideBarState] = useState({
		errorPagesMenuOpen: false,
		userPagesMenuOpen: false,
	});

	// const toggleMenuState = (menuState: string) => {
	// 	function checkMenu() {
	// 		let status: boolean = false;
	// 		if (menuState === "errorPagesMenuOpen") {
	// 			status = sideBarState.errorPagesMenuOpen;
	// 		} else if (menuState === "userPagesMenuOpen") {
	// 			status = sideBarState.userPagesMenuOpen;
	// 		}
	// 		return status;
	// 	}

	// 	if (checkMenu()) {
	// 		setSideBarState({ 
	// 			...sideBarState,
	// 			[menuState]: false
	// 		 });
	// 	} else if (Object.keys(sideBarState).length === 0) {
	// 		setSideBarState({ 
	// 			...sideBarState,
	// 			[menuState]: true
	// 		 });
	// 	} else {
	// 		Object.keys(sideBarState).forEach((i) => {
	// 			setSideBarState({ 
	// 				...sideBarState,
	// 				[i]: false
	// 			 });
	// 		});
	// 		setSideBarState({ 
	// 			...sideBarState,
	// 			[menuState]: true
	// 		 });
	// 	}
	// }

	useEffect(() => {
		onRouteChanged();
		// add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
		const body = document.querySelector("body");
		document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
			el.addEventListener("mouseover", function () {
				if (body?.classList.contains("sidebar-icon-only")) {
					el.classList.add("hover-open");
				}
			});
			el.addEventListener("mouseout", function () {
				if (body?.classList.contains("sidebar-icon-only")) {
					el.classList.remove("hover-open");
				}
			});
		});

		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		onRouteChanged();

		// eslint-disable-next-line
	}, [location]);

	const auth = useAuth();

	// useEffect(() => {
	// 	console.log(auth?.adminProfile)
	// }, [auth?.adminProfile]);

	
	const isPathActive = (path: string) => {
		return location.pathname.startsWith(path);
	}

	function onRouteChanged() {
		document.querySelector("#sidebar")?.classList.remove("active");
		Object.keys(sideBarState).forEach((i) => {
			setSideBarState({ 
				...sideBarState,
				[i]: false
			 });
		});

		const dropdownPaths = [
			// { path: "/apps", state: "appsMenuOpen" },
			// { path: "/basic-ui", state: "basicUiMenuOpen" },
			// { path: "/form-elements", state: "formElementsMenuOpen" },
			// { path: "/tables", state: "tablesMenuOpen" },
			// { path: "/icons", state: "iconsMenuOpen" },
			// { path: "/charts", state: "chartsMenuOpen" },
			{ path: "/user-pages", state: "userPagesMenuOpen" },
			{ path: "/error-pages", state: "errorPagesMenuOpen" },
		];

		dropdownPaths.forEach((obj) => {
			if (isPathActive(obj.path)) {
				setSideBarState({ 
					...sideBarState,
					[obj.state]: true
				 });
			}
		});
	}

	
		return (
			<nav className="sidebar sidebar-offcanvas" id="sidebar">
				<div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top logo-div">
					<a className="sidebar-brand brand-logo" href="index.html">
						<img src={logo} alt="logo" />
					</a>
					<a className="sidebar-brand brand-logo-mini" href="index.html">
						<img src={logoMini} alt="logo" />
					</a>
				</div>
				<ul className="nav">
					{/* <li className="nav-item profile">
						<div className="profile-desc">
							<div className="profile-pic">
								<div className="count-indicator">
									<img className="img-xs rounded-circle " src="" alt="profile" />
									<span className="count bg-success"></span>
								</div>
								<div className="profile-name">
									<h5 className="mb-0 font-weight-normal">
										<Trans>Henry Klein</Trans>
									</h5>
									<span>
										<Trans>Gold Member</Trans>
									</span>
								</div>
							</div>
							<Dropdown alignRight>
								<Dropdown.Toggle as="a" className="cursor-pointer no-caret">
									<i className="mdi mdi-dots-vertical"></i>
								</Dropdown.Toggle>
								<Dropdown.Menu className="sidebar-dropdown preview-list">
									<a href="!#" className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
										<div className="preview-thumbnail">
											<div className="preview-icon bg-dark rounded-circle">
												<i className="mdi mdi-settings text-primary"></i>
											</div>
										</div>
										<div className="preview-item-content">
											<p className="preview-subject ellipsis mb-1 text-small">
												<Trans>Account settings</Trans>
											</p>
										</div>
									</a>
									<div className="dropdown-divider"></div>
									<a href="!#" className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
										<div className="preview-thumbnail">
											<div className="preview-icon bg-dark rounded-circle">
												<i className="mdi mdi-onepassword  text-info"></i>
											</div>
										</div>
										<div className="preview-item-content">
											<p className="preview-subject ellipsis mb-1 text-small">
												<Trans>Change Password</Trans>
											</p>
										</div>
									</a>
									<div className="dropdown-divider"></div>
									<a href="!#" className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
										<div className="preview-thumbnail">
											<div className="preview-icon bg-dark rounded-circle">
												<i className="mdi mdi-calendar-today text-success"></i>
											</div>
										</div>
										<div className="preview-item-content">
											<p className="preview-subject ellipsis mb-1 text-small">
												<Trans>To-do list</Trans>
											</p>
										</div>
									</a>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</li> */}
					<li className="nav-item nav-category">
						<span className="nav-link">
							<Trans>Navigation</Trans>
						</span>
					</li>
					<li className={` ${isPathActive("/dashboard") ? "nav-item menu-items active" : "nav-item menu-items"} ${auth?.adminProfile?.role === "designer" && "hide"}`}>
						<Link className="nav-link" to="/dashboard">
							<span className="menu-icon">
								<i className="mdi mdi-speedometer"></i>
							</span>
							<span className="menu-title">
								<Trans>Dashboard</Trans>
							</span>
						</Link>
					</li>
					{/* <li className={isPathActive("/user-pages") ? "nav-item menu-items active" : "nav-item menu-items"}>
						<div className={sideBarState.userPagesMenuOpen ? "nav-link menu-expanded" : "nav-link"} onClick={() => toggleMenuState("userPagesMenuOpen")} data-toggle="collapse">
							<span className="menu-icon">
								<i className="mdi mdi-security"></i>
							</span>
							<span className="menu-title">
								<Trans>User Pages</Trans>
							</span>
							<i className="menu-arrow"></i>
						</div>
						<Collapse in={sideBarState.userPagesMenuOpen}>
							<div>
								<ul className="nav flex-column sub-menu">
									<li className="nav-item">
										{" "}
										<Link className={isPathActive("/login") ? "nav-link active" : "nav-link"} to="/login">
											<Trans>Login</Trans>
										</Link>
									</li>
									<li className="nav-item">
										{" "}
										<Link className={isPathActive("/user-pages/register-1") ? "nav-link active" : "nav-link"} to="/user-pages/register-1">
											<Trans>Register</Trans>
										</Link>
									</li>
								</ul>
							</div>
						</Collapse>
					</li> */}
					{/* <li className="nav-item nav-category">
						<span className="nav-link">
							<Trans>More</Trans>
						</span>
					</li>
					<li className={isPathActive("/error-pages") ? "nav-item menu-items active" : "nav-item menu-items"}>
						<div className={sideBarState.errorPagesMenuOpen ? "nav-link menu-expanded" : "nav-link"} onClick={() => toggleMenuState("errorPagesMenuOpen")} data-toggle="collapse">
							<span className="menu-icon">
								<i className="mdi mdi-lock"></i>
							</span>
							<span className="menu-title">
								<Trans>Error Pages</Trans>
							</span>
							<i className="menu-arrow"></i>
						</div>
						<Collapse in={sideBarState.errorPagesMenuOpen}>
							<div>
								<ul className="nav flex-column sub-menu">
									<li className="nav-item">
										{" "}
										<Link className={isPathActive("/error-pages/error-404") ? "nav-link active" : "nav-link"} to="/error-pages/error-404">
											404
										</Link>
									</li>
								</ul>
							</div>
						</Collapse>
					</li> */}
					<li className={` ${isPathActive("/templates") ? "nav-item menu-items active" : "nav-item menu-items"} ${auth?.adminProfile?.role === "viewer" && "hide"}`}>
						<Link className="nav-link" to="/templates">
							<span className="menu-icon">
								<i className="mdi mdi-view-dashboard"></i>
							</span>
							<span className="menu-title">
								<Trans>Templates</Trans>
							</span>
						</Link>
					</li>
					<li className={` ${isPathActive("/categories") ? "nav-item menu-items active" : "nav-item menu-items"} ${auth?.adminProfile?.role === "viewer" && "hide"}`}>
						<Link className="nav-link" to="/categories">
							<span className="menu-icon">
								<i className="mdi mdi-library-books"></i>
							</span>
							<span className="menu-title">
								<Trans>Categories</Trans>
							</span>
						</Link>
					</li>
					<li className={` ${isPathActive("/campaigns") ? "nav-item menu-items active" : "nav-item menu-items"} ${auth?.adminProfile?.role === "designer" && "hide"}`}>
						<Link className="nav-link" to="/campaigns">
							<span className="menu-icon">
								<i className="mdi mdi-palette"></i>
							</span>
							<span className="menu-title">
								<Trans>Campaigns</Trans>
							</span>
						</Link>
					</li>
					<li className={` ${isPathActive("/accounting/billing-cycles") ? "nav-item menu-items active" : "nav-item menu-items"} ${auth?.adminProfile?.role === "designer" && "hide"}`}>
						<Link className="nav-link" to="/accounting/billing-cycles">
							<span className="menu-icon">
								<i className="mdi mdi-pen"></i>
							</span>
							<span className="menu-title">
								<Trans>Accounting</Trans>
							</span>
						</Link>
					</li>
					<li className={` ${isPathActive("/users") ? "nav-item menu-items active" : "nav-item menu-items"} ${auth?.adminProfile?.role === "designer" && "hide"}`}>
						<Link className="nav-link" to="/users">
							<span className="menu-icon">
								<i className="mdi mdi-account-multiple-outline"></i>
							</span>
							<span className="menu-title">
								<Trans>Users</Trans>
							</span>
						</Link>
					</li>
					<li className={` ${isPathActive("/admins") ? "nav-item menu-items active" : "nav-item menu-items"} ${(auth?.adminProfile?.role === "designer" || auth?.adminProfile?.role === "viewer" || auth?.adminProfile?.role === "editor") && "hide"}`}>
						<Link className="nav-link" to="/admins">
							<span className="menu-icon">
								<i className="mdi mdi-security"></i>
							</span>
							<span className="menu-title">
								<Trans>Admins</Trans>
							</span>
						</Link>
					</li>
				</ul>
			</nav>
		);


	
}

export default Sidebar;
