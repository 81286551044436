import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import useAuth from '../../auth/useAuth';
import CategoryComponent from '../../components/CategoryComponent';
import NotAuthorized from '../../shared/NotAuthorized';
// import CheckFileUploadSize from '../../shared/CheckFileUploadSize';
import Roller from '../../shared/Roller';
import './Categories.scss';

export interface CategoryProps {
	coverImage: string,
	createdAt: string
	description: string,
	id: number,
	name: string,
	slug: string,
	updatedAt: string
}

interface NewCategoryData {
    name: string,
    description: string,
    slug: string,
    coverImage: string,
}

const Categories: React.FC = () => {
    const auth = useAuth();
    let token = localStorage.getItem("inbrAdminToken");
	const history = useHistory();

    if (token) {
        token = JSON.parse(token);
    }

    const [loadingCategories, setLoadingCategories] = useState(true);
    const [categories, setCategories] = useState<[]>([]);
    const [error, setError] = useState("");
    const [notAuthorized, setNotAuthorized] = useState(false);
    
    function getCategories () : void {
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/categories`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoadingCategories(false);
            if (result.error) {
                setError(result.error);
            } else {
                setCategories(result.categories);
            }
			console.log(result);
		})
		.catch(error => {
			setError(error.error);
			setLoadingCategories(false);
		});
	}

    useEffect(() => {
        // if (auth?.adminProfile?.role === "designer") {
        //     setNotAuthorized(true);
        //     setLoadingCategories(false);   
        // } else {
            // getCategories();
            // setNotAuthorized(false);  
        // }
        // eslint-disable-next-line
    }, [auth?.adminProfile]);

    useEffect(() => {
        getCategories();
        setNotAuthorized(false); 
        // eslint-disable-next-line 
    }, [])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
	const [coverImageLoading, setCoverImageLoading] = useState(false);
    const [categoryState, setCategoryState] = useState<NewCategoryData>({
        name: "",
        description: "",
        slug: "",
        coverImage: ""
    });

    const [creatingCategory, setCreatingCategory] = useState(false);

    // const coverImage = document.getElementById("coverImage");

    function uploadFile (file: any, name: any) : void {
		setCoverImageLoading(true);
		let myHeaders = new Headers();
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let formData = new FormData();
        // formdata.append("file", fileInput.files[0], "/C:/Users/Pelumi/Pictures/Animes/Kimetsu no Yaiba/2413000.jpg");
        formData.append("file", file, file.name);

        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
        };

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/uploads`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setCoverImageLoading(false);
            if (result.status === "ok") {
                setCategoryState({
                    ...categoryState,
                    coverImage: result.upload.finalUrl
                })
            } else if (result.error === "Invalid Permission") {
                Swal.fire({
                    icon: "error",
                    title: "Sorry you are not authorized to perform this operation"
                }).then(() => handleClose());
			} else if (result.error) {
                Swal.fire({
                    icon: "error",
                    title: result.error
                });
            }
			// console.log(result);
		})
		.catch(error => {
			setError(error);
            Swal.fire({
                icon: "error",
                title: error.error
            })
			setCoverImageLoading(false);
		});
	}

    function createCategory () : void {
		setCreatingCategory(true);

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

        const sentBody = JSON.stringify({
            name: categoryState.name,
            description: categoryState.description,
            slug: categoryState.slug,
            coverImage: categoryState.coverImage
        });

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
            body: sentBody
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/categories`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setCreatingCategory(false);
			// console.log(result);
            if (result.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: result.message,
                }).then(() => {
                    // setLoadingCategories(true);
                    // getCategories();
                    handleClose();
                    history.push(`/category/${result.category.id}`);
                });
            } else if (result.error === "Invalid Permission") {
                Swal.fire({
                    icon: "error",
                    title: "Sorry you are not authorized to perform this operation"
                }).then(() => handleClose());
			} else if (result.status === "error" ) {
                Swal.fire({
                    icon: "error",
                    title: result.error
                });
            }
		})
		.catch(error => {
			setError("There seems to be a problem, please check your connection and try again");
            // Swal.fire({
            //     icon: "error",
            //     title: error.error
            // });
            console.log(error)
			setCreatingCategory(false);
		});
	}

    const handleCreateCategory = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (categoryState.coverImage === "") {
            Swal.fire({
                icon: "info",
                title: "Please upload a cover image before continuing"
            });
        } else {
            // console.log(categoryState);
            createCategory();
        }
    }

    const checkUploadSize = (fileUploadElement: any) => {
        // console.log(fileUploadElement?.files)
		if (typeof fileUploadElement?.files != "undefined") {
			// let size = parseFloat(fileUploadElement.files[0].size / 1024).toFixed(2);
			let size = parseFloat(fileUploadElement?.files[0].size);
			// console.log(size);
			if (size > 5242880 || size === 0) {
				Swal.fire({
					icon: "error",
					title: "File size is larger than 5MB please choose another image",
				});
				return false;
			} else {
				return true;
			}
		}
	};

    const handleCategoryFormInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		if ((e.target as HTMLInputElement).files && (e.target as HTMLInputElement).files?.length) {
            // console.log((e.target as HTMLInputElement).files);

			const answer = checkUploadSize((e.target as HTMLInputElement));
            if (answer) {
                setCoverImageLoading(!coverImageLoading);
                uploadFile((e.target as HTMLInputElement).files?.[0], name);
            }
		} else {
			setCategoryState({
				...categoryState,
				[name]: value,
			});
		}
	};

    // useEffect(() => console.log(categories, "categories"), [categories]);
    // useEffect(() => console.log(categoryState, "categoryState"), [categoryState]);

    if (loadingCategories) {
		return <Roller />
	} else if (error === "Invalid Permission") {
		return <NotAuthorized />
	} else if (error) {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{error}
		</div>
	} else if (notAuthorized) {
        return <NotAuthorized />
    }

    return (
        <>
            <div>
                <button type="button" className={`btn btn-success btn-md ${auth?.adminProfile?.role === "viewer" ? "not-allowed" : ""}`} disabled={auth?.adminProfile?.role === "viewer" ? true : false } onClick={handleShow}>Create new category</button>
            </div>
            <div className="categories-div">
                {
                    categories.map((category: CategoryProps) => <CategoryComponent key={category.id} {...category} />)
                }
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>CREATE NEW CATEGORY</Modal.Title>
                </Modal.Header>
                {
                    creatingCategory ? (
                        <Roller />
                    ) : (
                        <Modal.Body>
                            <form className="form-horizontal" onSubmit={handleCreateCategory}>
                                <div className="form-group row">
                                    <label htmlFor="inputName" className="col-sm-2 col-form-label">
                                        Name
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control edit-template-form-control" required id="inputName" placeholder="Name" name="name" value={categoryState.name} onChange={handleCategoryFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="description" className="col-sm-2 col-form-label">
                                        Description
                                    </label>
                                    <div className="col-sm-10">
                                        <textarea className="form-control edit-template-form-control" id="description" required rows={5} placeholder="Short description about this list" name="description" value={categoryState.description} onChange={handleCategoryFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="slug" className="col-sm-2 col-form-label">
                                        Slug
                                    </label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control edit-template-form-control" required id="slug" placeholder="Slug" name="slug" value={categoryState.slug} onChange={handleCategoryFormInputChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="coverImage" className="input-image-label">Cover image</label>
                                    <div className="d-flex align-items-start" style={{ marginLeft: "1%"}}>
                                        <div className="image-div">
                                            <img
                                                className="img-circle image"
                                                src={categoryState.coverImage ? categoryState.coverImage : "/assets/img/boxed-bg.jpg"}
                                                alt="cover"
                                            />
                                            {!coverImageLoading && !categoryState.coverImage ? (
                                                <span className="no-image-selected">No Profile Photo Uploaded</span>
                                            ) : coverImageLoading ? (
                                                <div className="lds-roller lds-roller-black" style={{display: "flex", position: "absolute", top: "20%", left: "20%"}}>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="align-self-center">
                                            <label id="inputProfilePhotoLabel" className="btn btn-sm btn-default input-image-label choose-btn" htmlFor="coverImage">
                                                Choose cover image
                                            </label>
                                            <input
                                                type="file"
                                                accept=".png, .jpeg, .jpg"
                                                className="input-image"
                                                id="coverImage"
                                                onChange={handleCategoryFormInputChange}
                                                name="coverImage"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-block btn-success p-2.5">Create Category</button>
                            </form>
                        </Modal.Body>
                    )
                }
            </Modal>
        </>
    )
}

export default Categories
