import React, { useEffect } from 'react';
import { AnalyticsCardProps } from '../dashboard/Dashboard';
import '../dashboard/Dashboard.scss';

interface AInfoProps {
    dataToShow: AnalyticsCardProps['data']
}

const AnalyticsInfo: React.FC<AInfoProps> = ({ dataToShow }) => {
    return (
        <div className="col-12">
            <div className="d-flex align-items-center align-self-start analytic-div">
                <p className="text-success ml-2 mb-0 font-weight-medium">Campaign Clicks</p>
                    &nbsp; - &nbsp;
                <h3 className="mb-0">{dataToShow.campaignClicks}</h3>
            </div>
            <div className="d-flex align-items-center align-self-start analytic-div">
                <p className="text-success ml-2 mb-0 font-weight-medium">Campaign Views</p>
                    &nbsp; - &nbsp;
                <h3 className="mb-0">{dataToShow.campaignViews}</h3>
            </div>
            <div className="d-flex align-items-center align-self-start analytic-div">
                <p className="text-success ml-2 mb-0 font-weight-medium">Published Campaigns</p>
                    &nbsp; - &nbsp;
                <h3 className="mb-0">{dataToShow.publishedCampaigns}</h3>
            </div>
            <div className="d-flex align-items-center align-self-start analytic-div">
                <p className="text-success ml-2 mb-0 font-weight-medium">Generated Images</p>
                    &nbsp; - &nbsp;
                <h3 className="mb-0">{dataToShow.generatedImages}</h3>
            </div>
            <div className="d-flex align-items-center align-self-start analytic-div">
                <p className="text-success ml-2 mb-0 font-weight-medium">Email Sent</p>
                    &nbsp; - &nbsp;
                <h3 className="mb-0">{dataToShow.emailSent}</h3>
            </div>
            <div className="d-flex align-items-center align-self-start analytic-div">
                <p className="text-success ml-2 mb-0 font-weight-medium">New Users</p>
                    &nbsp; - &nbsp;
                <h3 className="mb-0">{dataToShow.newUsers}</h3>
            </div>
            <div className="d-flex align-items-center align-self-start analytic-div">
                <p className="text-success ml-2 mb-0 font-weight-medium">Paid Billing Cycles</p>
                    &nbsp; - &nbsp;
                <h3 className="mb-0">{dataToShow.paidBillingCycles}</h3>
            </div>
            <div className="d-flex align-items-center align-self-start analytic-div">
                <p className="text-success ml-2 mb-0 font-weight-medium">Unpaid Billing Cycles</p>
                    &nbsp; - &nbsp;
                <h3 className="mb-0">{dataToShow.unpaidBillingCycles}</h3>
            </div>
            <div className="d-flex align-items-center align-self-start analytic-div">
                <p className="text-success ml-2 mb-0 font-weight-medium">Paid Revenue</p>
                    &nbsp; - &nbsp;
                <h3 className="mb-0">{dataToShow.paidRevenue}</h3>
            </div>
            <div className="d-flex align-items-center align-self-start analytic-div">
                <p className="text-success ml-2 mb-0 font-weight-medium">Paid Revenue</p>
                    &nbsp; - &nbsp;
                <h3 className="mb-0">{dataToShow.unpaidRevenue}</h3>
            </div>
            <div className="d-flex align-items-center align-self-start analytic-div">
                <p className="text-success ml-2 mb-0 font-weight-medium">No Overage Billing Cycles</p>
                    &nbsp; - &nbsp;
                <h3 className="mb-0">{dataToShow.noOverageBillingCycles}</h3>
            </div>
            <div className="d-flex align-items-center align-self-start analytic-div">
                <p className="text-success ml-2 mb-0 font-weight-medium">Overage Billing Cycles</p>
                    &nbsp; - &nbsp;
                <h3 className="mb-0">{dataToShow.withOverageBillingCycles}</h3>
            </div>
            <div className="d-flex align-items-center align-self-start analytic-div">
                <p className="text-success ml-2 mb-0 font-weight-medium">Estimated Revenue</p>
                    &nbsp; - &nbsp;
                <h3 className="mb-0">{dataToShow.estimatedRevenue.toFixed(2)}</h3>
            </div>
        </div>
    )
}

const AnalyticsCard : React.FC<AnalyticsCardProps> = ({ type, fetching, error, data, custom }) => {
    const getTheDate = (theDate: string) => {
        let date = new Date(theDate);
        return date.toLocaleDateString('en-US',  { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    }

    useEffect(() => console.log(custom, "custom"), [custom]);

    return (
        <div className={`col-xl-3 col-sm-6 grid-margin stretch-card ${custom?.dataFrom && "center-div" }`}>  
            <div className="card">
                <div className="card-body">
                    <div className="card-title">
                        {custom ? (
                            <p className="text-success ml-2 mb-0 font-weight-medium custom-analytic">From {custom.dataFrom !== "" ? getTheDate(custom.dataFrom) :"Please Wait"} to {custom.dataTo !== "" ? getTheDate(custom.dataTo) : "Please Wait"}</p>
                        ) : type}
                    </div>
                    <div className="row">
                        {
                            fetching ? (
                                <div className="spm-hourglass" style={{ margin: "auto" }}></div>
                            ) : error ? (
                                <h3 className="mb-0 text-danger" style={{fontSize: 9}}>Error fetching please refresh page or check your internet connection</h3>
                            ) : custom?.dataFrom ? (
                                <AnalyticsInfo dataToShow={custom} />
                            ) : data.campaignClicks !== -1 && (
                                <AnalyticsInfo dataToShow={data} />
                            )
                        }
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default AnalyticsCard
